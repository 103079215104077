<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      title="添加班组人员"
      custom-class="custom-dialog_75"
      :track="{
        name_zh: `基础数据/人员管理/排班管理/抽屉-${title}/弹窗-添加班组人员`,
        trackName: $route.path
      }"
      @close="handleClose"
    >
      <div class="action">
        <header-component ref="childHeader" @headleQuery="headleQuery" />
      </div>
      <el-table
        ref="multipleTable"
        v-loading="loading.getTableData"
        :data="tableData"
        border
        @selection-change="handleSelect"
      >
        <template v-for="item in TABLECOLUMN">
          <el-table-column
            v-if="item.type"
            :key="item.prop"
            :type="item.type"
            :width="item.width"
            :selectable="headleCheckSelec"
          />
          <el-table-column
            v-else
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <span v-if="item.filter">{{ filterLabel(scope.row[item.prop], item.arr) }}</span>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <nh-pagination
        class="margin-top_normal"
        v-bind="pagination"
        @change="handlePaginationChange"
      />
      <template #footer>
        <div>
          <nh-button type="primary" @click="handleAdd">
            确 定
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import HeaderComponent from './components/header.vue';
import TABLECOLUMN from './fileds';
import { getStaffPage } from '../../api';

export default {
  name: 'SelectStaffDialog',
  components: { HeaderComponent },
  mixins: [loadingMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  emits: ['onSelect'],
  data() {
    return {
      TABLECOLUMN,
      dialogVisible: false,
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      formData: {},
      tableData: [],
      disabledTable: [],
      select: [],
      loading: {
        getTableData: false,
      },
    };
  },
  methods: {
    init(data) {
      this.disabledTable = data;
      this.dialogVisible = true;
      this.select = [];
      this.$nextTick(() => {
        this.$refs.childHeader.handleQuery();
      });
    },
    headleQuery(data) {
      this.formData = data;
      this.getTableData();
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
      this.getTableData();
    },
    async getTableData() {
      const resp = await getStaffPage(this.pagination, this.formData);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
      this.pagination.page = resp.page;
      this.pagination.size = resp.size;
    },
    headleCheckSelec(row) {
      return !this.disabledTable.some((item) => item.staffCode === row.staffCode);
    },
    handleSelect(selection) {
      this.select = selection;
    },
    handleAdd() {
      this.$emit('onSelect', this.select);
      this.close();
    },
    close() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.childHeader.handleReset();
    },
    filterLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-70dfe5 {
  color: #70dfe5;
}

.action {
  padding: 15px 0;
}

.center {
  text-align: center;
}

.dialog-footer {
  text-align: left;

  .btn {
    text-align: right;
  }
}
</style>
