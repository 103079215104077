<template>
  <div class="edit-page">
    <nh-drawer
      v-model="drawerVisible"
      :before-close="handleClose"
      destroy-on-close
      title="新增/编辑排班"
      size="1112px"
      :track="{
        name_zh: `基础数据/人员管理/排班管理/抽屉-${title}`,
        trackName: $route.path
      }"
    >
      <el-form
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        label-width="120px"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="班组名称:" prop="groupName">
              <el-input v-model="formData.groupName" placeholder="" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作业时间区间:" prop="time">
              <el-date-picker
                v-model="formData.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item label="备注:" prop="remark">
              <el-input
                v-model="formData.remark"
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="action">
        <div class="btn">
          <nh-button @click="handleAddSelect">
            选择员工
          </nh-button>
          <nh-button @click="handleAddFloater">
            添加临时工
          </nh-button>
          <nh-button>
            导入
          </nh-button>
        </div>
        <div class="search">
          <div>
            <p>
              班组总人数:<span>{{ planTotalNum }}</span>
            </p>
          </div>
          <div>
            <p>
              正式工:<span>{{ regularNum }}</span>
            </p>
          </div>
          <div>
            <p>
              临时工:<span>{{ temporaryNum }}</span>
            </p>
          </div>
          <el-input
            v-model="filterStaff"
            placeholder="可按名称或工号进行搜索"
            @input="handleSetTableData"
          />
        </div>
      </div>
      <el-table v-loading="loading.getDetail" :data="filterTableData">
        <template v-for="item in TABLECOLUMN">
          <el-table-column
            v-if="item.type"
            :key="item.prop"
            :type="item.type"
            :width="item.width"
          />
          <el-table-column
            v-else-if="item.prop === 'action'"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            fixed="right"
          >
            <template #default="scope">
              <nh-button
                type="text"
                :loading="loading.handleRemoveRow"
                @click="handleRemoveRow(scope.row)"
              >
                删除
              </nh-button>
              <nh-button
                v-if="!scope.row.account"
                type="text"
                :loading="loading.handleRemoveRow"
                @click="handleAssociated(scope.row)"
              >
                关联账号
              </nh-button>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <template v-if="item.prop === SCHEDULING_DICTIONARY.TASKS">
                <el-checkbox-group v-model="scope.row[item.prop]">
                  <el-checkbox
                    v-for="task in TASKS"
                    :key="task.value"
                    :label="task.value"
                  >
                    {{ task.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </template>
              <span v-else-if="item.ENUM">{{ item.ENUM[scope.row[item.prop]] }}</span>
              <span
                v-else-if="item.prop === SCHEDULING_DICTIONARY.ACCOUNT"
                :class="{ 'color-f00': !scope.row[item.prop] }"
              >{{ !scope.row[item.prop] ? "未关联" : scope.row[item.prop] }}</span>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <template #footer>
        <div class="footer">
          <nh-button
            type="primary"
            :loading="loading.submitForm"
            @click="submitForm"
          >
            保 存
          </nh-button>
        </div>
      </template>
    </nh-drawer>
    <select-staff-dialog
      ref="childSelectStaff"
      :title="title"
      @onSelect="handleAddStaff"
    />
    <add-floater-dialog
      ref="childAddFloater"
      :title="title"
      @onPushFloater="handlePushFloater"
    />
    <select-associated-dialog
      ref="childSelectAssociated"
      @handleSelectAssociated="handleSelectAssociated"
    />
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import AddFloaterDialog from './components/addFloaterDialog.vue';
import SelectStaffDialog from './components/selectStaffDialog.vue';
import SelectAssociatedDialog from './components/selectAssociatedDialog.vue';
import { SCHEDULING_DICTIONARY, TASKS } from '../fileds';
import TABLECOLUMN from './fileds';
import { schedulePlanDetail, schedulePlanUpsert } from '../api';

export default {
  name: 'Edit',
  components: {
    AddFloaterDialog,
    SelectStaffDialog,
    SelectAssociatedDialog,
  },
  mixins: [loadingMixin],
  emits: ['onSuccess'],
  data() {
    return {
      SCHEDULING_DICTIONARY,
      TASKS,
      TABLECOLUMN,
      title: '',
      filterStaff: '',
      planTotalNum: 0,
      regularNum: 0,
      temporaryNum: 0,
      tableData: [],
      filterTableData: [],
      formData: {},
      loading: {
        getDetail: false,
        handleRemoveRow: false,
        submitForm: false,
      },
      drawerVisible: false,
      id: '',
      rules: {
        groupName: [{ required: true, message: '请输入班组名称', trigger: 'blur' }],
        time: [
          {
            required: true,
            message: '请选择时间',
            trigger: 'change',
          },
        ],
      },
    };
  },
  created() {
    const param = this.$route.query;
    this.title = '新增';
    if (param.id) {
      this.title = '编辑';
      this.planTotalNum = param.planTotalNum;
      this.regularNum = param.regularNum;
      this.temporaryNum = param.temporaryNum;
      this.formData = {
        groupName: param.groupName,
        time: [Number(param.beginWorkTime), Number(param.endWorkTime)],
        remark: param.remark,
      };
      this.id = param.id;
      this.init(param.id);
    }
  },
  methods: {
    init(param) {
      this.drawerVisible = true;
      this.title = '新增';
      if (param.id) {
        this.title = '编辑';
        this.planTotalNum = param.planTotalNum;
        this.regularNum = param.regularNum;
        this.temporaryNum = param.temporaryNum;
        this.formData = {
          groupName: param.groupName,
          time: [Number(param.beginWorkTime), Number(param.endWorkTime)],
          remark: param.remark,
        };
        this.id = param.id;
        this.getDetail(param.id);
      }
    },
    async getDetail(id) {
      const resp = await schedulePlanDetail({ schedulePlanId: id });
      this.tableData = resp.map((item) => ({
        ...item,
        tasks: item.task ? item.task.split(',') : [],
      }));
      this.handleSetTableData();
    },
    handleSetTableData() {
      this.filterTableData = this.tableData.filter((data) => {
        if (this.filterStaff) {
          return (
            data.staffCode.toLowerCase().includes(this.filterStaff.toLowerCase())
            || data.staffName.toLowerCase().includes(this.filterStaff.toLowerCase())
          );
        }
        return true;
      });
    },
    handleAddSelect() {
      this.$refs.childSelectStaff.init(this.tableData);
    },
    handleAddStaff(data) {
      data.forEach((item) => {
        this.tableData.push({
          ...item,
          staffId: item.id,
          tasks: [],
        });
      });
      this.setNum();
      this.handleSetTableData();
    },
    handlePushFloater(data) {
      data.forEach((item) => {
        let flag = true;
        this.tableData.forEach((list, index) => {
          if (item.staffCode === list.staffCode) {
            flag = false;
            this.tableData[index] = {
              tasks: [],
              ...item,
              staffId: item.id,
            };
          }
        });
        if (flag) {
          this.tableData.push({
            ...item,
            staffId: item.id,
            tasks: [],
          });
        }
      });
      this.setNum();
      this.handleSetTableData();
    },
    handleAddFloater() {
      this.$refs.childAddFloater.init();
    },
    async handleRemoveRow(row) {
      try {
        await this.$confirm('是否删除?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
      } catch (error) {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
        return;
      }
      const removeTable = this.tableData.filter((item) => row.id !== item.id);
      this.tableData = removeTable;
      this.$message({
        type: 'success',
        message: '删除成功!',
      });
      this.handleSetTableData();
      this.setNum();
    },
    // 关联账号
    handleAssociated(row) {
      this.associatedId = row.id;
      this.$refs.childSelectAssociated.init(row.accountId);
    },
    handleSelectAssociated(data) {
      let index = null;
      this.tableData.forEach((item, i) => {
        if (this.associatedId === item.id) {
          index = i;
        }
      });
      Object.keys(data).forEach((item) => {
        this.tableData[index][item] = data[item];
      });
      this.associatedId = null;
      this.handleSetTableData();
    },
    setNum() {
      this.planTotalNum = this.tableData.length;
      let [regularNum = 0, temporaryNum = 0] = [];
      this.tableData.forEach((item) => {
        if (item.staffType === SCHEDULING_DICTIONARY.TEMPORARY) {
          temporaryNum += 1;
        } else {
          regularNum += 1;
        }
      });
      this.regularNum = regularNum;
      this.temporaryNum = temporaryNum;
    },
    async submitForm() {
      await this.$refs.ruleForm.validate();
      const [beginWorkTime, endWorkTime] = this.formData.time;
      const data = {
        ...this.formData,
        beginWorkTime,
        endWorkTime,
        schedulerPlanDetails: this.tableData,
        id: this.id,
      };
      await schedulePlanUpsert(data);
      this.handleClose();
      this.$emit('onSuccess');
      this.$message({
        type: 'success',
        message: '操作成功!',
      });
    },
    handleClose() {
      this.drawerVisible = false;
      this.filterStaff = '';
      this.planTotalNum = 0;
      this.regularNum = 0;
      this.temporaryNum = 0;
      this.tableData = [];
      this.filterTableData = [];
      this.formData = {};
      this.id = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-page {
  margin-bottom: 16px;
}

.color-f00 {
  color: #f00;
}

.action {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;

  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    p {
      margin-right: 20px;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        color: #f00;
      }
    }
  }
}

.btn {
  margin-top: 20px;
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>
