import { SCHEDULING_EMPTYPE_ENUM } from '../fileds';

const TABLECOLUMN = [{
  label: '员工工号',
  width: 80,
  prop: 'staffCode',
}, {
  label: '员工姓名',
  prop: 'staffName',
  width: 80,
}, {
  label: '手机号',
  prop: 'telephone',
  width: 120,
}, {
  label: '员工类型',
  prop: 'staffType',
  filter: true,
  width: 80,
  ENUM: SCHEDULING_EMPTYPE_ENUM,
}, {
  label: '关联的账号',
  prop: 'account',
  width: 120,
}, {
  label: '可分配任务',
  prop: 'tasks',
  width: 600,
}];

export default TABLECOLUMN;
