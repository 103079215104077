<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      custom-class="custom-dialog_1250"
      title="添加临时工"
      :track="{
        name_zh: `基础数据/人员管理/排班管理/抽屉-${title}/弹窗-添加临时工`,
        trackName: $route.path
      }"
      @close="handleClose"
    >
      <el-form
        ref="formData"
        :model="formData"
        :inline="true"
      >
        <ul>
          <li v-for="(floater, index) in formData.floaters" :key="floater.key">
            <el-form-item
              label="工号"
              :prop="'floaters.'+index + '.staffCode'"
              :rules="{
                required: true, message: '工号不能为空', trigger: 'blur'
              }"
            >
              <el-input v-model="floater.staffCode" />
            </el-form-item>
            <el-form-item
              label="姓名"
              :prop="'floaters.'+index + '.staffName'"
              :rules="{
                required: true, message: '姓名不能为空', trigger: 'blur'
              }"
            >
              <el-input v-model="floater.staffName" />
            </el-form-item>
            <el-form-item
              label="联系电话"
              :prop="'floaters.'+ index + '.telephone'"
              :rules="[
                {required: true, message: '电话不能为空', trigger: 'blur'},
                {validator: validatePhone, trigger: 'blur' }
              ]"
            >
              <el-input v-model="floater.telephone" />
            </el-form-item>
            <el-form-item
              label="关联账号"
              :prop="'floaters.'+index + '.accountId'"
            >
              <el-select
                v-model="floater.accountId"
                filterable
                :filter-method="filterAssociated"
                @change="(val) => {headleAccountChange(val, index)}"
              >
                <el-option
                  v-for="item in filteredAssociatedList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="handleDisabled(item.value)"
                >
                  <span>{{ item.label }}/{{ item.mobile }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <nh-button type="text" @click="handleRemoveFloater(floater)">
              删除
            </nh-button>
          </li>
        </ul>
      </el-form>
      <nh-button type="text" @click="handleAddFloater">
        继续添加
      </nh-button>
      <template #footer>
        <div>
          <nh-button
            type="primary"
            :loading="loading.handleSubmit"
            @click="handleSubmit"
          >
            确 定
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import { SCHEDULING_DICTIONARY } from '../../fileds';
import { staffSaveBatch, warehouseUserBind } from '../../api';

export default {
  name: 'AddFloaterDialog',
  mixins: [loadingMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  emits: ['onPushFloater'],
  data() {
    return {
      dialogVisible: false,
      accountList: [],
      filteredAssociatedList: [],
      formData: {
        floaters: [],
      },
      loading: {
        handleSubmit: false,
      },
    };
  },
  created() {},
  methods: {
    init() {
      this.dialogVisible = true;
      this.formData.floaters = [];
      this.getAccountList();
      this.handleAddFloater();
    },
    async getAccountList() {
      const resolve = await warehouseUserBind();
      this.accountList = resolve.map((item) => ({
        value: item.userId,
        label: item.realName,
        mobile: item.mobile,
      }));
      this.filteredAssociatedList = [...this.accountList];
    },
    validatePhone(rule, value, callback) {
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        return callback(new Error('请输入正确的电话号码'));
      }
      return callback();
    },
    filterAssociated(val) {
      if (val) { // val存在
        this.filteredAssociatedList = this.accountList.filter((item) => {
          if (item.label.includes(val)
          || item.label.toUpperCase().includes(val.toUpperCase())
          || item.mobile.includes(val)
          || item.mobile.toUpperCase().includes(val.toUpperCase())) {
            return true;
          }
          return false;
        });
      } else { // val为空时，还原数组
        this.filteredAssociatedList = [...this.accountList];
      }
    },
    handleDisabled(val) {
      if (val) {
        return this.formData.floaters.some((item) => (item.accountId === val));
      }
      return false;
    },
    handleAddFloater() {
      this.formData.floaters.push({
        key: Date.now(),
        staffCode: null,
        staffName: null,
        telephone: null,
        accountId: null,
        status: SCHEDULING_DICTIONARY.WORK,
        staffType: SCHEDULING_DICTIONARY.TEMPORARY,
      });
    },
    handleRemoveFloater(item) {
      const index = this.formData.floaters.indexOf(item);
      if (index !== -1) {
        this.formData.floaters.splice(index, 1);
      }
    },
    headleAccountChange(val, index) {
      const findAccount = this.filteredAssociatedList.find((item) => item.value === val);
      this.formData.floaters[index].account = findAccount ? findAccount.label : '';
    },
    async handleSubmit() {
      await this.$refs.formData.validate();
      const data = {
        staffs: this.formData.floaters,
      };
      const resp = await staffSaveBatch(data);
      this.$message({ type: 'success', message: '保存成功' });
      this.$emit('onPushFloater', resp);
      this.close();
    },
    close() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.formData.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-70dfe5 {
  color: #70dfe5;
}

.center {
  text-align: center;
}

.dialog-footer {
  text-align: left;

  .btn {
    text-align: right;
  }
}
</style>
