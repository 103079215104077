<template>
  <nh-drawer
    v-model="drawerVisible"
    v-loading="loading.getDetail"
    :before-close="handleClose"
    destroy-on-close
    title="排班管理"
    size="1112px"
  >
    <nh-drawer-container title="排班详情">
      <el-form>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="班组名称：">
              {{ param.groupName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作业时间区间：">
              {{ param.workTime }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item label="备注：">
              {{ param.remark }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="action">
        <div class="btn" />
        <div class="search">
          <div>
            <p>
              班组总人数：<span>{{ param.planTotalNum }}</span>
            </p>
          </div>
          <div>
            <p>
              正式工：<span>{{ param.regularNum }}</span>
            </p>
          </div>
          <div>
            <p>
              临时工：<span>{{ param.temporaryNum }}</span>
            </p>
          </div>
          <el-input
            v-model="filterStaff"
            placeholder="可按名称或工号进行搜索"
            @input="handleSetTableData"
          />
        </div>
      </div>
      <el-table :data="filterTableData" border>
        <template v-for="item in TABLECOLUMN">
          <el-table-column
            v-if="item.type"
            :key="item.prop"
            :type="item.type"
            :width="item.width"
          />
          <el-table-column
            v-else
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :min-width="item.width"
          >
            <template #default="scope">
              <span v-if="item.ENUM">{{ item.ENUM[scope.row[item.prop]] }}</span>
              <span
                v-else-if="item.prop === 'formal'"
                :class="{ 'color-f00': !scope.row[item.prop] }"
              >{{ !scope.row[item.prop] ? "未关联" : scope.row[item.prop] }}</span>
              <template v-else-if="item.prop === SCHEDULING_DICTIONARY.TASKS">
                <el-checkbox-group v-model="scope.row[item.prop]">
                  <el-checkbox
                    v-for="task in TASKS"
                    :key="task.value"
                    :label="task.value"
                    disabled
                  >
                    {{ task.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </nh-drawer-container>
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import {
  SCHEDULING_DICTIONARY, TASKS, SCHEDULING_EMPTYPE, SCHEDULING_TYPE,
} from '../fileds';
import TABLECOLUMN from './fileds';
import { schedulePlanDetail } from '../api';

export default {
  name: 'Details',

  mixins: [loadingMixin],
  data() {
    return {
      SCHEDULING_DICTIONARY,
      TASKS,
      SCHEDULING_EMPTYPE,
      SCHEDULING_TYPE,
      TABLECOLUMN,
      selectVal: '',
      tableData: [],
      filterTableData: [],
      filterStaff: '',
      drawerVisible: false,
      loading: {
        getDetail: false,
      },
    };
  },
  methods: {
    init(row) {
      this.drawerVisible = true;
      if (row.id) {
        this.param = row;
        this.getDetail(row.id);
      }
    },
    async getDetail(id) {
      const resp = await schedulePlanDetail({ schedulePlanId: id });
      this.tableData = resp.map((item) => ({
        ...item,
        tasks: item.task ? item.task.split(',') : [],
      }));
      this.handleSetTableData();
    },
    handleSetTableData() {
      this.filterTableData = this.tableData.filter((data) => {
        if (this.filterStaff) {
          return (
            data.staffCode.toLowerCase().includes(this.filterStaff.toLowerCase())
            || data.staffName.toLowerCase().includes(this.filterStaff.toLowerCase())
          );
        }
        return true;
      });
    },
    handleClose() {
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.color-f00 {
  color: #f00;
}

.action {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;

  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    p {
      margin-right: 20px;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        color: #f00;
      }
    }
  }
}

.btn {
  margin-top: 20px;
}
</style>
