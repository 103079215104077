<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields"
      @submit="handleSearch"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '基础数据/人员管理/排班管理/[新建]'
          }"
          @click="handleAdd"
        >
          新建
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/人员管理/排班管理/[导入]'
          }"
        >
          导入
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/人员管理/排班管理/[导出]'
          }"
        >
          导出
        </nh-button>
      </template>
      <template #groupName="{row}">
        <nh-button type="text" @click="handleDetails(row)">
          {{ row.groupName }}
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleEdit(row)">
          编辑
        </nh-button>
        <nh-button type="text" @click="handleCopy(row)">
          复制
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <Details ref="details" />
  <plan-edit ref="planEdit" @onSuccess="getTableData" />
</template>
<script>
import loadingMixin from '@thales/loading';
import Base from '@/constant/pageNames/base';
import Details from './details/index.vue';
import PlanEdit from './edit/index.vue';
import { SCHEDULING_DICTIONARY, TABLECOLUMN, serchFields } from './fileds';
import { getSchedulePlanPage, getSchedulePlanCopy } from './api';

const moment = require('moment');

export default {
  name: Base.SCHEDULING_PLAN,
  components: { Details, PlanEdit },
  mixins: [loadingMixin],
  data() {
    return {
      SCHEDULING_DICTIONARY,
      serchFields,
      TABLECOLUMN,
      tableData: [],
      formData: {},
      loading: {
        getTableData: false,
      },
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  activated() {
    this.getTableData();
  },
  methods: {
    handleSearch() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await getSchedulePlanPage(pagination, this.formData);
      this.tableData = resp.records.map((item) => ({
        ...item,
        workTime: `${moment(item.beginWorkTime).format('YYYY/MM/DD HH:mm:SS')}~${moment(
          item.endWorkTime,
        ).format('YYYY/MM/DD HH:mm:SS')}`,
      }));
      this.pagination.total = resp.total;
    },
    handleAdd() {
      this.$refs.planEdit.init({});
    },
    handleEdit(row) {
      this.$refs.planEdit.init({ ...row });
    },
    handleDetails(row) {
      this.$refs.details.init({ ...row });
    },
    async handleCopy(row) {
      try {
        await this.$confirm('是否复制?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
      } catch (error) {
        this.$message({
          type: 'info',
          message: '已取消复制',
        });
        return;
      }
      await getSchedulePlanCopy({ id: row.id });
      this.$message({
        type: 'success',
        message: '复制成功!',
      });
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.action {
  padding: 15px 0;
}

.cursor_col {
  cursor: pointer;
  color: #06b880;
}

.text-btn:not(:first-of-type) {
  padding-left: 8px;
}
</style>
