import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 排班计划新增或编辑
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11214
 */
export function schedulePlanUpsert(data) {
  return wmsPlusHttp.post('/warehouse_management_system/schedule_plan/upsert', data);
}
/**
 * @description: 排班计划分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11216
 */
export function getSchedulePlanPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/schedule_plan/page', data, { params });
}
/**
 * @description: 排班计划导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11218
 */
export function schedulePlanExport() {
  return wmsPlusHttp.post('/warehouse_management_system/schedule_plan/export', {}, { fileName: '排班计划', extension: 'xlsx' });
}
/**
 * @description: 编辑时根据排班计划id查看详情接口
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11220
 */
export function schedulePlanDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/schedule_plan/detail', data);
}
/**
 * @description: 员工 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11016
 */

export function getStaffPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/staff/page', data, {
    params,
  });
}
/**
 * @description: 排班计划复制
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11290
 */

export function getSchedulePlanCopy(data) {
  return wmsPlusHttp.post('/warehouse_management_system/schedule_plan/copy', data);
}
/**
 * @description: 批量新增零食员工
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11284
 */

export function staffSaveBatch(data) {
  return wmsPlusHttp.post('/warehouse_management_system/staff/save_batch', data);
}
/**
 * @description: 查询仓库绑定信息 /关联账号
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/13044
 */

export function warehouseUserBind() {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_user/bind/list', {});
}
