<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      title="选择关联账号"
      center
      @close="handleClose"
    >
      <nh-dialog-form
        ref="formData"
        :model="formData"
        :rules="rules"
      >
        <nh-dialog-form-item label="关联账号" prop="accountId">
          <el-select
            v-model="formData.accountId"
            filterable
            :filter-method="filterAssociated"
          >
            <el-option
              v-for="item in filteredAssociatedList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span class="left">{{ item.label }}/{{ item.mobile }}</span>
            </el-option>
          </el-select>
        </nh-dialog-form-item>
      </nh-dialog-form>
      <template #footer>
        <div>
          <nh-button type="primary" @click="handleAssociated">
            确 定
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import { warehouseUserBind } from '../../api';

export default {
  name: 'SelectAssociatedDialog',
  mixins: [loadingMixin],
  emits: ['handleSelectAssociated'],
  data() {
    return {
      dialogVisible: false,
      accountList: [],
      filteredAssociatedList: [],
      formData: {
        accountId: '',
      },
      rules: {
        accountId: [
          { required: true, message: '请选择关联账号', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    init(id) {
      this.dialogVisible = true;
      this.formData = {
        ...this.formData,
        accountId: id,
      };
      this.getAccountList();
    },
    async getAccountList() {
      const resolve = await warehouseUserBind();
      this.accountList = resolve.map((item) => ({
        value: item.userId,
        label: item.realName,
        mobile: item.mobile,
      }));
      this.filteredAssociatedList = [...this.accountList];
    },
    filterAssociated(val) {
      if (val) { // val存在
        this.filteredAssociatedList = this.accountList.filter((item) => {
          if (item.label.includes(val)
          || item.label.toUpperCase().includes(val.toUpperCase())
          || item.mobile.includes(val)
          || item.mobile.toUpperCase().includes(val.toUpperCase())) {
            return true;
          }
          return false;
        });
      } else { // val为空时，还原数组
        this.filteredAssociatedList = [...this.accountList];
      }
    },
    handleAssociated() {
      this.$refs.formData.validate((valid) => {
        const { accountId } = this.formData;
        const warehouseUser = this.accountList.find((item) => item.value === accountId);
        if (valid) {
          this.$emit('handleSelectAssociated', { ...this.formData, account: warehouseUser.label });
          this.close();
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.formData.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-70dfe5 {
  color: #70dfe5;
}

.action {
  padding: 15px 0;
}

.center {
  text-align: center;
}

.dialog-footer {
  text-align: left;

  .btn {
    text-align: right;
  }
}

.left {
  float: left;
}
</style>
