import { SCHEDULING_EMPTYPE, SCHEDULING_TYPE } from '../../fileds';

const TABLECOLUMN = [
  {
    type: 'index',
    width: 50,
    prop: 'index',
  }, {
    type: 'selection',
    width: 50,
    prop: 'selection',
  },
  {
    label: '员工工号',
    width: 50,
    prop: 'staffCode',
  }, {
    label: '员工姓名',
    prop: 'staffName',
    width: 120,
  }, {
    label: '员工类型',
    prop: 'staffType',
    filter: true,
    arr: SCHEDULING_EMPTYPE,
    width: 120,
  }, {
    label: '状态',
    prop: 'status',
    filter: true,
    arr: SCHEDULING_TYPE,
    width: 80,
  }, {
    label: '联系电话',
    prop: 'telephone',
    width: 120,
  }, {
    label: '邮箱',
    prop: 'mail',
    width: 120,
  }, {
    label: '关联账号',
    prop: 'account',
    width: 120,
  }];

export default TABLECOLUMN;
