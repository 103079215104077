<template>
  <div class="header-component">
    <el-form
      ref="ruleForm"
      :inline="true"
      :model="formData"
    >
      <el-form-item label="员工工号：" prop="staffCode">
        <el-input v-model="formData.staffCode" placeholder="" />
      </el-form-item>
      <el-form-item label="员工姓名：" prop="staffName">
        <el-input v-model="formData.staffName" placeholder="" />
      </el-form-item>
      <el-form-item label="员工类型：" prop="staffType">
        <el-select v-model="formData.staffType" placeholder="--请选择--">
          <el-option
            v-for="item in SCHEDULING_EMPTYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-select
          v-model="formData.status"
          disabled
          placeholder="--请选择--"
        >
          <el-option
            v-for="item in SCHEDULING_TYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div class="button">
      <nh-button @click="handleQuery">
        查询
      </nh-button>
      <nh-button @click="handleReset">
        重置
      </nh-button>
      <slot />
    </div>
  </div>
</template>
<script>
import { SCHEDULING_DICTIONARY, SCHEDULING_EMPTYPE, SCHEDULING_TYPE } from '../../../fileds';

export default {
  name: 'HeaderComponent',
  emits: ['headleQuery'],
  data() {
    return {
      SCHEDULING_EMPTYPE,
      SCHEDULING_TYPE,
      formData: {
        staffCode: null,
        staffName: null,
        staffType: null,
        status: null,
      },
    };
  },
  created() {
    this.formData.status = SCHEDULING_DICTIONARY.WORK;
  },
  methods: {
    handleQuery() {
      const data = {};
      Object.keys(this.formData).forEach((item) => {
        if (this.formData[item]) {
          data[item] = this.formData[item];
        }
      });
      this.$emit('headleQuery', data);
    },
    handleReset() {
      this.$refs.ruleForm.resetFields();
      this.handleQuery();
    },
  },
};
</script>
