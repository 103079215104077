import { FormType } from '@/constant/form';
import { formatKeyValueObject } from '@/utils/base';

const SCHEDULING_DICTIONARY = {
  WORK: 'WORK',
  RESIGN: 'RESIGN',
  ACCOUNT: 'account',
  ACTION: 'action',
  TASKS: 'tasks',
  GROUP_NAME: 'groupName',
  TEMPORARY: 'TEMPORARY',
};
const TASKS = [{
  label: '收货',
  value: 'CHECK_IN',
}, {
  label: '上架',
  value: 'PUT_AWAY',
}, {
  label: '拣货',
  value: 'PICKING',
}, {
  label: '分拣',
  value: 'SORTING',
}, {
  label: '集货',
  value: 'PICK_UP',
}, {
  label: '移库',
  value: 'MOVE',
}, {
  label: '盘点',
  value: 'COUNT',
}];
const SCHEDULING_EMPTYPE = [{
  label: '正式工',
  value: 'REGULAR',
}, {
  label: '临时工',
  value: 'TEMPORARY',
}];
const SCHEDULING_EMPTYPE_ENUM = formatKeyValueObject(SCHEDULING_EMPTYPE);
const SCHEDULING_TYPE = [{
  label: '在职',
  value: 'WORK',
}, {
  label: '离职',
  value: 'RESIGN',
}];
const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '生效时间',
  prop: 'workTime',
  minWidth: 320,
}, {
  label: '班次名称',
  prop: 'groupName',
  minWidth: 120,
}, {
  label: '计划总人数',
  prop: 'planTotalNum',
  minWidth: 120,
}, {
  label: '正式工',
  prop: 'regularNum',
  minWidth: 120,
}, {
  label: '临时工',
  prop: 'temporaryNum',
  minWidth: 120,
}, {
  label: '班组说明',
  prop: 'remark',
  minWidth: 120,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 220,
}];

export {
  SCHEDULING_DICTIONARY,
  SCHEDULING_EMPTYPE,
  SCHEDULING_EMPTYPE_ENUM,
  SCHEDULING_TYPE, TASKS, TABLECOLUMN,
};

export const serchFields = [
  {
    label: '班组名称',
    prop: 'groupName',
    component: FormType.INPUT,
  },
];
